import {
    tableState,
    tableMutations,
    tableActions
} from "@tt/vue-components"

import orderService from "@/services/OrderService";
import Vue from 'vue';

const orderComments = {
    namespaced: true,
    state: {
        ...tableState,
        openState: 'open',
        resolvedState: 'resolved',
        loading: false,
        editComment: {},
        sorting: { sortBy: [], sortDesc: [true] }
    },
    mutations: {
        ...tableMutations,
        SET_EDIT_COMMENT (state, comment) {
            state.editComment = comment;
        },
        SET_COMMENT_ON_ORDER (state, { order, comment }) {
            let existingComment = order.comments
                .findIndex(orderComment => orderComment.id === comment.id);
            if (existingComment > -1) {
                Vue.set(order.comments, existingComment, comment);
            } else {
                order.comments.push(comment);
            }
        },
        REMOVE_COMMENT_ON_ORDER (state, { order, comment }) {
            order.comments = order.comments
                .filter(orderComment => orderComment.id !== comment.id);
        }
    },
    actions: {
        ...tableActions,
        add ({ commit, dispatch }, { order, comment }) {
            return new Promise((resolve, reject) => {
                orderService.orderComments.create({...comment, order: order['@id']})
                    .then((comment) => {
                        commit("SET_COMMENT_ON_ORDER", { comment, order });
                        dispatch("ordersOverview/updateItem", order, { root: true })
                        resolve(comment);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err["hydra:description"]);
                    });
            });
        },
        edit ({ rootState, commit, dispatch }, comment) {
            return new Promise((resolve, reject) => {
                orderService.orderComments.patch(comment.id, comment)
                    .then((comment) => {
                        let order = rootState.ordersOverview.order.item;
                        commit("SET_COMMENT_ON_ORDER", { comment, order });
                        dispatch("ordersOverview/updateItem", order, { root: true })
                        resolve(comment);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err["hydra:description"]);
                    });
            });
        },
        remove ({ rootState, commit }, comment) {
            return new Promise((resolve, reject) => {
                orderService.orderComments.patch(comment.id, { active: false })
                    .then((response) => {
                        let order = rootState.ordersOverview.order.item;
                        commit("REMOVE_COMMENT_ON_ORDER", { comment, order });
                        resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err["hydra:description"]);
                    });
            });
        }
    },
    getters: {
        orderComments: (state, getters, rootState) => {
            return rootState.ordersOverview.order.item?.comments.filter((commentItem) => commentItem.active) ?? [];
        },
        sortedOrderComments: (state, getters) => {
            return [
                ...getters.openOrderComments.sort((a, b) => a.createdBy > b.createdBy ? 1 : -1),
                ...getters.closedOrderComments.sort((a, b) => a.createdBy > b.createdBy ? -1 : 1)
            ]
        },
        openOrderComments: (state, getters) => {
            return getters.orderComments
                .filter(comment => comment.status === state.openState)
        },
        closedOrderComments: (state, getters) => {
            return getters.orderComments
                .filter(comment => comment.status === state.resolvedState)
        }
    }
};

export default orderComments;
