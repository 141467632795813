import validationRun from "@/store/modules/ordersOverview/validationRun";
import orderService from "@/services/OrderService";
import {tableMutations} from "@tt/vue-components";

const order = {
  namespaced: true,
  state: {
    loading: false,
    item: null,
    validationRuns: []
  },
  modules: {
    validationRun
  },
  mutations: {
    ...tableMutations,
    SET_VIEW(state, view) {
      state.view = view;
    },
    SET_VALIDATION_RUNS(state, validationRuns) {
      state.validationRuns = validationRuns;
    }
  },
  actions: {
    fetchOrder(context, orderId) {
      orderService.orders.get(orderId)
        .then(json => {
          context.commit("SET_ITEM", json);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          context.commit("SET_LOADING", false);
        });
    },
    refreshOrder(context) {
      if (context.state.item !== null) {
        context.dispatch("fetchOrder", context.state.item.id);
      }
    },
    patch({ commit, dispatch }, order) {
      return new Promise((resolve, reject) => {
        orderService.orders.patch(order.id, order.body)
          .then((order) => {
            commit("SET_ITEM", order);
            dispatch("ordersOverview/updateItem", order, { root: true })
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    orderCancel({ dispatch, state }, orderCancel) {
      return new Promise((resolve, reject) => {
        orderService.orderCancels.create(orderCancel)
            .then(() => {
              dispatch('fetchOrder', state.item.id)
              dispatch("ordersOverview/selectItem", state.item, { root: true })
              resolve();
            })
            .catch(err => {
              reject(err["hydra:description"]);
            });
      });
    },
    orderReturn({ dispatch, state }, orderReturn) {
      return new Promise((resolve, reject) => {
        orderService.orderReturns.create(orderReturn)
            .then(() => {
              dispatch('fetchOrder', state.item.id)
              dispatch("ordersOverview/selectItem", state.item, { root: true })
              resolve();
            })
            .catch(err => {
              reject(err["hydra:description"]);
            });
      });
    },
  }
};

export default order;
